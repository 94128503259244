<template>
    <div class="events-detail-settings">
        <FormGroupBuilder v-if="event" :fields="ioFields" :data="event" />
        <b-form-group
            v-if="event"
            :label-cols-sm="3"
            :label-cols-lg="4"
            label-for="is-active"
            :label-size="size"
            label="Tags"
            description=""
            :disabled="busy.data || busy.mutation"
        >
            <CheckboxSelect
                :options="tagsSelect"
                :size="size"
                :busy="busy.data"
                :selectSize="5"
                v-model="event.tags"
            />
        </b-form-group>
    </div>
</template>

<script>
import Size from "@/mixins/Size/Size";
import FormGroupBuilder from "@/components/FormGroupBuilder/FormGroupBuilder";
import CheckboxSelect from "@/components/CheckboxSelect/CheckboxSelect";

import { mapActions, mapGetters } from "vuex";

export default {
    name: "EventDetailSettings",
    props: ["id"],
    mixins: [Size],
    components: { FormGroupBuilder, CheckboxSelect },
    data() {
        return {
            ioFields: {
                id: {
                    key: "id",
                    label: "ID",
                    disabled: true
                },
                token: {
                    key: "token",
                    label: "Kurs-/Veranstaltungsnummer",
                    disabled: false
                },
                title: {
                    key: "title",
                    label: "Titel"
                },
                type: {
                    key: "type",
                    label: "Typ",
                    type: "select",
                    options: [{ value: null, text: "Keine Auswahl" }]
                },
                section: {
                    key: "section",
                    label: "Bereich",
                    type: "select",
                    option: [{ value: null, text: "Keine Auswahl" }]
                }
            }
        };
    },
    computed: {
        ...mapGetters({
            busy: "events/getBusy",
            tags: "eventsTags/getTags",
            tagsSelect: "eventsTags/getTagsForSelect"
        }),
        event() {
            const event = this.$store.getters["events/getEvent"](this.id);
            return event;
        },
        typesSelect() {
            const types = this.$store.getters["eventsTypes/getTypesForSelect"];
            types.unshift({ value: null, text: "Keine Auswahl" });
            return types;
        },
        sectionsSelect() {
            const sections = this.$store.getters[
                "eventsSections/getSectionsForSelect"
            ];
            sections.unshift({ value: null, text: "Keine Auswahl" });
            return sections;
        }
    },
    watch: {
        typesSelect: {
            handler(val) {
                this.ioFields.type.options = val;
            }
        },
        sectionsSelect: {
            handler(val) {
                this.ioFields.section.options = val;
            }
        }
    },
    created() {
        this.fetchEventsTypes();
        this.fetchEventsSections();
        this.fetchEventsTags();
    },
    methods: {
        ...mapActions({
            fetchEventsTypes: "eventsTypes/fetchTypes",
            fetchEventsSections: "eventsSections/fetchSections",
            fetchEventsTags: "eventsTags/fetchTags"
        })
    }
};
</script>
