<template>
    <MainContainer>
        <div class="events-detail">
            <b-row>
                <b-col cols="12">
                    <h2>
                        <span
                            >Veranstaltung
                            <template v-if="event">
                                "{{ event.title }}"
                            </template>
                            bearbeiten</span
                        >
                        <b-button
                            size="md"
                            variant="primary"
                            class=" ml-3 pull-right"
                            :disabled="busy.mutation"
                            @click="handleModification"
                        >
                            <Spinner
                                v-if="busy.mutation"
                                small
                                variant="light"
                            />
                            Speichern</b-button
                        >
                    </h2>
                </b-col>
                <b-col cols="12">
                    <b-tabs content-class="mt-3">
                        <b-tab title="Einstellungen">
                            <b-tabs pills vertical>
                                <b-tab title="Allgemein">
                                    <EventDetailSettings :id="id" />
                                </b-tab>
                                <b-tab title="Zeitraum">
                                    <EventDateSettings :id="id" />
                                </b-tab>
                                <b-tab title="Zuweisungen">
                                    <EventRelationSettings :id="id" />
                                </b-tab>
                            </b-tabs>
                        </b-tab>
                        <b-tab title="Verträge" lazy>
                            <EventContracts :id="id" />
                        </b-tab>
                        <b-tab title="Teilnehmende" lazy>
                            <EventParticipants :id="id" />
                        </b-tab>
                        <b-tab title="Formulare" lazy>
                            <EventForms :id="id" />
                        </b-tab>
                        <!--<b-tab title="Protokoll" lazy>
                            <EventProtocol :id="id" />
                        </b-tab>-->
                        <b-tab title="Controlling" lazy disabled>
                            <p>Funktion noch nicht fertig gestellt</p>
                        </b-tab>
                    </b-tabs>
                </b-col>
            </b-row>
        </div>
    </MainContainer>
</template>

<script>
import Size from "@/mixins/Size/Size";

import MainContainer from "@/components/MainContainer/MainContainer";
import FormGroupBuilder from "@/components/FormGroupBuilder/FormGroupBuilder";
import EventDetailSettings from "@/components/EventDetailSettings/EventDetailSettings";
import EventDateSettings from "@/components/EventDateSettings/EventDateSettings";
import EventRelationSettings from "@/components/EventRelationSettings/EventRelationSettings";
import EventParticipants from "@/components/EventParticipants/EventParticipants";
import EventForms from "@/components/EventForms/EventForms";
import EventContracts from "@/components/EventContracts/EventContracts";
import EventProtocol from "@/components/EventProtocol/EventProtocol";
import Spinner from "@/components/Spinner/Spinner";
import { mapActions, mapGetters } from "vuex";

export default {
    name: "EventsDetail",
    mixins: [Size],
    components: {
        MainContainer,
        FormGroupBuilder,
        EventDetailSettings,
        EventRelationSettings,
        EventDateSettings,
        EventParticipants,
        EventForms,
        EventContracts,
        EventProtocol,
        Spinner
    },
    computed: {
        ...mapGetters({
            busy: "events/getBusy",
            dirty: "events/getDirty"
        }),
        id() {
            return this.$route.params.id;
        },
        isNew() {
            return this.id == "new";
        },
        event() {
            const event = this.$store.getters["events/getEvent"](this.id);
            return event;
        }
    },
    created() {
        this.fetch();
    },
    beforeMount() {
        window.addEventListener("beforeunload", this.preventNav);
        this.$once("hook:beforeDestroy", () => {
            window.removeEventListener("beforeunload", this.preventNav);
        });
    },
    beforeRouteLeave(to, from, next) {
        if (this.dirty && !this.isNew) {
            if (!window.confirm("Ohne Speichern fortfahren?")) {
                return;
            }
        }
        next();
    },
    watch: {
        event: {
            handler() {
                if (!this.dirty) {
                    this.$store.commit("events/SET_DIRTY", true);
                }
            },
            immediate: false,
            deep: true
        }
    },
    methods: {
        ...mapActions({
            fetchEventByID: "events/fetchEventByID"
        }),
        async fetch() {
            await this.fetchEventByID(this.id);
            this.$store.commit("events/SET_DIRTY", false);
        },
        async handleModification() {
            if (this.isNew) {
                const newForm = await this.$store.dispatch(
                    "events/createEvent",
                    {
                        ...this.event
                    }
                );
                this.$router.push("/events/" + newForm.id);
            } else {
                await this.$store.dispatch("events/editEvent", {
                    ...this.event
                });
                this.$store.commit("events/SET_DIRTY", false);
            }
        },
        preventNav(event) {
            if (!this.dirty || this.isNew) {
                return;
            }
            event.preventDefault();
            event.returnValue = "";
        }
    }
};
</script>
