<template>
    <div class="event-participants">
        <div class="general-functions">
            <b-row>
                <b-col cols="12">
                    <b-dropdown
                        v-if="!isNew"
                        text="Hinzufügen"
                        :size="size"
                        variant="outline-primary"
                    >
                        <b-dropdown-item @click="openModal(modals.addNewId)"
                            >Neue Person</b-dropdown-item
                        >
                        <b-dropdown-item
                            @click="openModal(modals.addFormPersonId)"
                            >Aus Formularantwort</b-dropdown-item
                        >
                    </b-dropdown>
                    <b-button
                        class="ml-1"
                        :size="size"
                        :disabled="true"
                        variant="outline-primary"
                        @click="openModal(modals.mailId)"
                    >
                        Mail versenden
                    </b-button>
                    <b-dropdown
                        class="ml-1"
                        text="Listen generieren"
                        :size="size"
                        :disabled="true"
                        variant="outline-secondary"
                    >
                        <b-dropdown-item>TN-Liste</b-dropdown-item>
                        <b-dropdown-item>Anwesenheitsliste</b-dropdown-item>
                    </b-dropdown>
                    <TableExport
                        class="ml-1 d-inline"
                        :busy="busyExport"
                        :dataAvailable="dataAvailable"
                        :showPDF="false"
                        @exportExcel="exportExcel"
                        @exportPDF="exportPDF"
                    />
                </b-col>
                <b-col md="12" class="my-4">
                    <TableFilter v-model="filter.pattern" />
                </b-col>
            </b-row>
        </div>

        <template v-if="filter.id.length > 0">
            <PersonsList
                :showedFields="selectedFields"
                :fieldDefinition="fields"
                :filter="filter"
                :filterUpdate="filterUpdate"
            >
                <template #actions="{data}">
                    <b-button
                        variant="outline-primary"
                        size="sm"
                        class="mx-1"
                        @click="
                            openModificationModal({
                                record: data.item,
                                mode: 'edit'
                            })
                        "
                        >Bearbeiten</b-button
                    >
                    <b-button
                        variant="outline-danger"
                        size="sm"
                        class="mx-1"
                        @click="openDeleteModal({ record: data.item })"
                        >Zuweisung entfernen</b-button
                    >
                </template>
            </PersonsList>
        </template>
        <template v-else>
            <div class="small text-center">
                Es wurden noch keine Teilnehmenden zugewiesen
            </div>
        </template>

        <EditModal
            :id="modals.addNewId"
            size="xl"
            title="Neue Person(en) hinzufügen"
            :busy="busyAddingPerson"
            @ok="handleModification"
            @hidden="closeModal(modals.addNewId)"
        >
            <b-alert show variant="warning">
                Hier können Personen zu einer Veranstaltung hinzugefügt werden.
                Über die Felder kann eine Person direkt hinzugefügt werden. Über
                den Button "Person suchen" können die eingegeben Informationen
                aber auch genutzt werden um zu überprüfen ob eine Person schon
                in den Kontakten hinterlegt ist. Durch die Auswahl dieser Person
                kann dann der vorhandene Datensatz hinzugefügt werden.
            </b-alert>
            <PersonsEditView
                :allowedIoFields="allowedPersonsIoFields"
                :busy="busyAddingPerson"
                :data="modificationRecord"
            />
            <hr />
            <div class="d-block text-right">
                <b-button
                    :size="size"
                    :disabled="busyAddingPerson"
                    @click="searchExistingPerson"
                    >Person suchen</b-button
                >
            </div>
            <template v-if="Object.values(existingPersonsFilter).length > 0">
                <hr />
                <PersonsList
                    :selectable="false"
                    :fieldDefinition="fields"
                    :filterUpdate="existingPersonsFilterUpdate"
                    :filter="existingPersonsFilter"
                    :externalFetchMode="true"
                >
                    <template #actions="{data}">
                        <b-button
                            variant="outline-primary"
                            size="sm"
                            class="mx-1"
                            :disabled="busyAddingPerson"
                            @click="useExistingPerson(data)"
                            >Zuweisen</b-button
                        >
                    </template>
                </PersonsList>
            </template>
        </EditModal>

        <EditModal
            :id="modals.addFormPersonId"
            title="Person(en) aus Formularen hinzufügen"
            mode="default"
            size="xl"
            :busy="busyAddingPerson"
            :disabled="addFromPersonDisabled"
            @ok="handleModification"
            @hidden="closeModal(modals.addFromPersonId)"
        >
            <b-select
                :options="forms"
                v-model="addFormPersonSelectedForm"
                :disabled="busyForms.data || busyAddingPerson"
                :size="size"
            />
            <hr />
            <template v-if="addFormPersonSelectedForm">
                <b-button
                    class="mb-3"
                    :size="size"
                    :disabled="busyAddingPerson"
                    @click="toggleAllFormsAnswersSelectedRows"
                    >Alle
                    <template v-if="addFromPersonSelection.length > 0"
                        >abwählen</template
                    ><template v-else>anwählen</template></b-button
                >
                <b-table
                    :fields="formsFields"
                    :items="formsAnswers"
                    :busy="busyFormsAnswers.data"
                    ref="formsAnswersTable"
                    selectable
                    small
                    @row-selected="onFormsAnswerSelect"
                >
                    <template #table-busy>
                        <div class="text-center text-secondary my-2">
                            <b-spinner
                                class="mr-1 align-middle"
                                small
                            ></b-spinner>
                            <strong>Daten werden geladen...</strong>
                        </div>
                    </template>

                    <template #head()="data">
                        {{ data.label }}
                        <b-select
                            :size="size"
                            :options="fieldRelationSelectData"
                            v-model="addFormPersonFieldRelation[data.column]"
                        ></b-select>
                    </template>
                </b-table>
            </template>
        </EditModal>

        <EditModal
            :id="modals.mailId"
            title="Mail an Teilnehmende versenden"
            mode="default"
            size="xl"
            okButton="Senden"
        >
            <b-tabs pills card vertical>
                <b-tab title="E-Mail" active>
                    <b-form-group label="Absendename" label-for="sender_name">
                        <b-input id="sender_name" :size="size" />
                    </b-form-group>
                    <b-form-group
                        label="Absendeadresse"
                        label-for="sender_mail"
                    >
                        <b-input id="sender_mail" :size="size" />
                    </b-form-group>
                    <b-form-group label="Betreff" label-for="subject">
                        <b-input id="subject" :size="size" />
                    </b-form-group>
                    <hr />
                    <vue-editor id="body"></vue-editor>
                </b-tab>
                <b-tab title="Emfpänger*innen">
                    <b-table
                        :fields="mailFields"
                        :items="selected"
                        small
                        show-empty
                    >
                        <template #empty>
                            <div class="text-center text-secondary my-2">
                                Es wurden keine Empfänger*innen ausgewählt
                            </div>
                        </template>

                        <template #cell(lastname)="data">
                            {{ data.item.firstname }} {{ data.item.lastname }}
                        </template>
                    </b-table>
                </b-tab>
            </b-tabs>
        </EditModal>

        <EditModal
            :busy="busyPersons.mutation"
            :mode="modificationMode"
            size="lg"
            @ok="handleModification"
        >
            <b-tabs pills card vertical>
                <b-tab title="Stammdaten" active>
                    <PersonsEditView
                        :allowedIoFields="allowedPersonsIoFields"
                        :data="modificationRecord"
                    />
                </b-tab>
                <b-tab title="Kontaktdaten">
                    <PersonContactView :data="modificationRecord" />
                </b-tab>
            </b-tabs>
        </EditModal>

        <DeleteModal
            :mode="modificationMode"
            :record="modificationRecord"
            :fields="currentRecordFields"
            :selected="selected"
            :busy="busyParticipants.mutation"
            @ok="handleModification"
        >
        </DeleteModal>
    </div>
</template>

<script>
import shortid from "shortid";

import ModelView from "@/mixins/ModelView/ModelView";
import Size from "@/mixins/Size/Size";
import Forms from "@/mixins/Forms/Forms";
import DateFormat from "@/mixins/Date/Date";

import EditModal from "@/components/EditModal/EditModal";
import DeleteModal from "@/components/DeleteModal/DeleteModal";
import TableExport from "@/components/TableExport/TableExport";
import TableFilter from "@/components/TableFilter/TableFilter";
import FormGroupBuilder from "@/components/FormGroupBuilder/FormGroupBuilder";
import PersonsList from "@/components/PersonsList/PersonsList";
import PersonsEditView from "@/components/PersonsEditView/PersonsEditView";
import PersonContactView from "@/components/PersonContactView/PersonContactView";
import { VueEditor } from "vue2-editor";

import { mapActions, mapGetters } from "vuex";

export default {
    name: "EventParticipants",
    props: ["id"],
    mixins: [ModelView, Size, Forms, DateFormat],
    components: {
        EditModal,
        DeleteModal,
        TableExport,
        TableFilter,
        FormGroupBuilder,
        PersonsList,
        PersonsEditView,
        PersonContactView,
        VueEditor
    },
    data() {
        return {
            filter: {
                pattern: "",
                id: []
            },
            filterUpdate: false,
            disableFilterUpdate: false,
            sort: {
                by: "entry_date",
                desc: false
            },
            fields: [
                {
                    key: "__selected",
                    label: "",
                    class: "text-center",
                    static: true,
                    position: "prepend"
                },
                {
                    key: "id",
                    label: "ID",
                    sortable: true,
                    disableMapping: true
                },
                {
                    key: "title",
                    label: "Titel",
                    sortable: false
                },
                {
                    key: "firstname",
                    label: "Vorname",
                    sortable: true
                },
                {
                    key: "lastname",
                    label: "Nachname",
                    sortable: true
                },
                {
                    key: "gender",
                    label: "Geschlecht",
                    sortable: true
                },
                {
                    key: "birthday",
                    label: "Geburtstag",
                    sortable: true
                },
                {
                    key: "birth_place",
                    label: "Geburtsort",
                    sortable: true
                },
                {
                    key: "origin_country",
                    label: "Herkunftsland",
                    sortable: true
                },
                {
                    key: "modification_date",
                    label: "Bearbeitungsdatum",
                    sortable: true,
                    disableMapping: true
                },
                {
                    key: "__actions",
                    label: "Aktionen",
                    class: "text-right",
                    static: true,
                    position: "append"
                }
            ],
            selectedFields: [
                "id",
                "title",
                "firstname",
                "lastname",
                "gender",
                "birthday",
                "birth_place",
                "origin_country",
                "modification_date",
                "email"
            ],
            allowedPersonsIoFields: [
                "id",
                "title",
                "firstname",
                "lastname",
                "gender",
                "birthday",
                "birth_place",
                "origin_country"
            ],
            actions: {
                createParticipant: "eventsParticipants/createData",
                edit: "persons/editData",
                delete: "eventsParticipants/deleteData"
            },
            modals: {
                addNewId: "add-new-person-modal",
                addFormPersonId: "add-form-person-modal",
                mailId: "mail-modal"
            },
            existingPersonsFilter: {},
            existingPersonsFilterUpdate: false,
            addFormPersonSelectedForm: null,
            addFormPersonFieldRelation: {},
            addFromPersonSelection: [],
            mailFields: [
                {
                    key: "lastname",
                    label: "Name"
                },
                {
                    key: "mail",
                    label: "Empfangsadresse"
                }
            ]
        };
    },
    computed: {
        ...mapGetters({
            busyParticipants: "eventsParticipants/getBusy",
            busyPersons: "persons/getBusy",
            busyForms: "forms/getBusy",
            busyFormsAnswers: "formsAnswers/getBusy",
            participants: "eventsParticipants/getData",
            relatedForms: "eventsForms/getData",
            forms: "forms/getSelectData",
            contactTypes: "contactTypes/getData"
        }),
        busy() {
            return this.busyParticipants.data;
        },
        busyAddingPerson() {
            return this.busyParticipants.mutation || this.busyPersons.mutation;
        },
        busyExport() {
            return {
                export: this.busyParticipants.export || this.busyPersons.export,
                excel: this.busyParticipants.excel || this.busyPersons.excel,
                pdf: this.busyParticipants.pdf || this.busyPersons.pdf
            };
        },
        dataAvailable() {
            return this.participants.length > 0;
        },
        isNew() {
            return this.id == "new";
        },
        formsAnswers() {
            const answers = this.$store.getters["formsAnswers/getAnswers"];
            return answers
                .filter(
                    item =>
                        !this.participants
                            .filter(item => item.form_answer_id)
                            .map(p => p.form_answer_id)
                            .includes(item.id)
                )
                .map(item => {
                    const obj = item.contents;
                    obj.id = item.id;
                    return obj;
                });
        },
        formsFields() {
            const fields = this.$store.getters["formsFields/getFields"];
            return fields.map(item => {
                return {
                    key: item.id.toString(),
                    label: item.field_label ?? item.id.toString(),
                    options: item.field_options,
                    mail:
                        item.field_settings &&
                        item.field_settings.corresponder_mail == 1
                            ? true
                            : false
                };
            });
        },
        fieldRelationSelectData() {
            return this.fields
                .filter(item => !item.static && !item.disableMapping)
                .map(item => {
                    return {
                        value: item.key,
                        text: item.label
                    };
                })
                .concat(this.contactTypes);
        },
        addFromPersonDisabled() {
            return !(
                Object.values(this.addFormPersonFieldRelation).length > 0 &&
                this.addFromPersonSelection.length > 0
            );
        }
    },
    mounted() {
        this.fetchParticipants(this.id);
    },
    watch: {
        participants: {
            handler(val) {
                if (!this.disableFilterUpdate) {
                    this.filter.id = val.map(item => item.persons_id);
                    this.filterUpdate = !this.filterUpdate;
                }
            },
            deep: true
        },
        addFormPersonSelectedForm: {
            handler(val) {
                this.fetchAnswers(val);
                this.fetchAnswersFields(val);
            }
        }
    },
    methods: {
        ...mapActions({
            fetchParticipants: "eventsParticipants/fetchData",
            fetchRelatedForms: "eventsForms/fetchData",
            fetchForms: "forms/fetchForms",
            fetchAnswers: "formsAnswers/fetchAnswers",
            fetchAnswersFields: "formsFields/fetchFields"
        }),
        async loadForms() {
            await this.fetchRelatedForms(this.id);
            const formIds = this.relatedForms;
            await this.fetchForms({ filter: { id: formIds } });
        },
        async exportExcel(format) {
            this.$store.dispatch("eventsParticipants/exportExcel", {
                id: this.id,
                format,
                filter: this.filter,
                selectedFields: this.selectedFields,
                selectedData: this._discardSelectedData(this.selected, "id")
            });
        },
        async exportPDF(orientation = "P") {
            this.$store.dispatch("eventsParticipants/exportPDF", {
                id: this.id,
                orientation,
                filter: this.filter,
                selectedFields: this.selectedFields,
                selectedData: this._discardSelectedData(this.selected, "id")
            });
        },
        searchExistingPerson() {
            this.existingPersonsFilter = this.modificationRecord;
            this.existingPersonsFilterUpdate = !this
                .existingPersonsFilterUpdate;
        },
        useExistingPerson(data) {
            this.modificationRecord = { ...data.item };
        },
        onFormsAnswerSelect(items) {
            this.addFromPersonSelection = items;
        },
        toggleAllFormsAnswersSelectedRows() {
            if (this.addFromPersonSelection.length == 0) {
                this.$refs.formsAnswersTable.selectAllRows();
            } else {
                this.$refs.formsAnswersTable.clearSelected();
            }
        },
        openModal(id) {
            if (id == this.modals.addFormPersonId) {
                this.loadForms();
            }
            this.modificationMode = id;
            this.modificationRecord = {};
            this.$bvModal.show(id);
        },
        closeModal(id) {
            this.existingPersonsFilter = {};
            this.addFormPersonSelectedForm = null;
        },
        /** Override */
        async handleModification(event) {
            event.preventDefault();

            var data = { ...this.modificationRecord };

            var answer = true;

            if (this.modificationMode == this.modals.addNewId) {
                if (data.id) {
                    data = {
                        event_id: this.id,
                        person_id: data.id
                    };
                } else {
                    const { modification } = await this.$store.dispatch(
                        "persons/createData",
                        data
                    );
                    data = {
                        event_id: this.id,
                        person_id: modification.id
                    };
                }
                answer = await this.$store.dispatch(
                    this.actions.createParticipant,
                    data
                );
            } else if (this.modificationMode == this.modals.addFormPersonId) {
                this.disableFilterUpdate = true;
                // Map fields
                data = this.addFromPersonSelection.map(item => {
                    const record = {};
                    Object.keys(this.addFormPersonFieldRelation).forEach(
                        key => {
                            const keyLabel = this.addFormPersonFieldRelation[
                                key
                            ];
                            const contactTypes = this.contactTypes.map(
                                ct => ct.value
                            );
                            if (contactTypes.includes(keyLabel)) {
                                if (!record["contacts"]) {
                                    record["contacts"] = [];
                                }
                                record["contacts"].push({
                                    id: "new_" + shortid.generate(),
                                    type: keyLabel,
                                    value: item[key]
                                });
                            } else {
                                record[keyLabel] = item[key];
                            }
                        }
                    );
                    record.form_answer_id = item.id;
                    return record;
                });

                for (const person of data) {
                    // Add persons
                    const { modification } = await this.$store.dispatch(
                        "persons/createData",
                        person
                    );
                    // Add participant
                    data = {
                        event_id: this.id,
                        person_id: modification.id,
                        form_answer_id: person.form_answer_id
                    };
                    const response = await this.$store.dispatch(
                        this.actions.createParticipant,
                        data
                    );
                    if (!response) {
                        answer = false;
                    }
                }
                this.disableFilterUpdate = true;
            } else if (this.modificationMode == "delete") {
                data = {
                    event_id: this.id,
                    person_id: data.id
                };
                answer = await this.$store.dispatch(this.actions.delete, data);
            } else {
                answer = await this.$store.dispatch(this.actions.edit, data);
            }

            if (answer) {
                this.$bvModal.hide(this.editModalId);

                this.$bvModal.hide(this.modificationMode);

                if (this.modificationMode == "delete") {
                    this.$bvModal.hide(this.removeModalId);
                }
            }
        }
    }
};
</script>
