<template>
    <div class="events-relation-settings">
        <template v-if="!isNew">
            <div class="events-relation-location">
                <strong>Standort</strong>
                <CheckboxSelect
                    v-if="event"
                    :size="size"
                    :options="locationsSelect"
                    v-model="event.locations"
                />
                <p class="small font-weight-bold text-center my-3">oder</p>
                <p class="text-center">
                    <b-button
                        :size="size"
                        :disabled="newLocation"
                        @click="addNewLocation"
                        >Standort anlegen</b-button
                    >
                </p>
            </div>
            <hr />
            <div
                class="events-relation-location-details"
                v-if="
                    (event && event.locations && event.locations.length > 0) ||
                        newLocation
                "
            >
                <b-tabs>
                    <b-tab
                        v-for="location in event.locations"
                        :key="location"
                        :title="locationsName(location)"
                    >
                        <div>
                            <LocationsEditView
                                :id="location"
                                :readonly="true"
                            />
                        </div>
                    </b-tab>
                    <b-tab v-if="newLocation" title="Neuer Standort">
                        <div>
                            <LocationsEditView
                                :active="newLocation"
                                :saveable="true"
                                @created="newLocationCreated"
                            />
                        </div>
                    </b-tab>
                </b-tabs>
            </div>
        </template>
        <template v-else>
            <b-alert :show="true" variant="warning"
                >Sie können noch keinen Standort zuweisen, solange die
                Veranstaltung nicht initial angelegt wurde. Drücken Sie einmal
                auf "Speichern" um die Veranstaltung anzulegen und Standorte
                zuzuweisen.</b-alert
            >
        </template>
    </div>
</template>

<script>
import Size from "@/mixins/Size/Size";
import FormGroupBuilder from "@/components/FormGroupBuilder/FormGroupBuilder";
import CheckboxSelect from "@/components/CheckboxSelect/CheckboxSelect";
import LocationsEditView from "@/components/LocationsEditView/LocationsEditView";

import { mapActions, mapGetters } from "vuex";

export default {
    name: "EventRelationSettings",
    props: ["id"],
    mixins: [Size],
    components: { FormGroupBuilder, CheckboxSelect, LocationsEditView },
    data() {
        return {
            newLocation: false
        };
    },
    watch: {},
    computed: {
        ...mapGetters({
            busy: "events/getBusy",
            locationsSelect: "locations/getSelectData",
            locationsName: "locations/getName"
        }),
        event() {
            const event = this.$store.getters["events/getEvent"](this.id);
            return event;
        },
        isNew() {
            return this.id == "new";
        }
    },
    created() {
        this.fetchLocations({ filter: null });
    },
    methods: {
        ...mapActions({
            fetchLocations: "locations/fetchData"
        }),
        addNewLocation() {
            this.newLocation = true;
        },
        newLocationCreated(val) {
            console.log(val);
            this.newLocation = false;
            this.event.locations.push(val.id);
        }
    }
};
</script>
