<template>
    <div class="event-protocol">
        <div class="event-protocol-menu">
            <b-button @click="openAddProtocolEntryModal" :size="size"
                >Neuen Eintrag hinzufügen</b-button
            >
        </div>
        <div class="event-protocol-list">
            <b-row>
                <b-col cols="8">
                    <ul class="protocol">
                        <li
                            class="protocol-entry"
                            v-for="protocolEntry in protocol"
                            :key="protocolEntry.id"
                            @click="selectProtocolEntry(protocolEntry.id)"
                        >
                            <div class="title">
                                {{ protocolEntry.title }}
                            </div>
                            <div class="description">
                                {{ protocolEntry.description }}
                            </div>
                            <div
                                v-if="protocolEntry.in_future == 1"
                                class="in_future"
                            >
                                {{ protocolEntry.in_future_date }}
                            </div>
                            {{ protocolEntry }}
                        </li>
                    </ul>
                </b-col>
                <b-col cols="4">
                    {{ selectedProtocolEntryId }}
                    <EventProtocolEdit :id="selectedProtocolEntryId" />
                </b-col>
            </b-row>
        </div>

        <EditModal
            :id="modals.addProtocolEntry"
            :busy="busyProtocol.mutation"
            mode="custom"
            title="Neuen Protokolleintrag erstellen"
            size="lg"
            @ok="addProtocolEntry"
        >
            <EventProtocolEdit />
        </EditModal>
    </div>
</template>

<script>
import Size from "@/mixins/Size/Size";

import EditModal from "@/components/EditModal/EditModal";
import EventProtocolEdit from "@/components/EventProtocol/EventProtocolEdit";
import { mapActions, mapGetters } from "vuex";

export default {
    name: "EventProtocol",
    props: ["id"],
    mixins: [Size],
    components: {
        EditModal,
        EventProtocolEdit
    },
    data() {
        return {
            modals: {
                addProtocolEntry: "add-protocol-entry"
            },
            selectedProtocolEntryId: null
        };
    },
    computed: {
        ...mapGetters({
            busyProtocol: "eventsProtocol/getBusy",
            protocol: "eventsProtocol/getData"
        })
    },
    mounted() {
        this.fetchProtocol(this.id);
    },
    methods: {
        ...mapActions({
            fetchProtocol: "eventsProtocol/fetchData"
        }),
        addProtocolEntry() {
            console.log("add protocol entry");
        },
        openAddProtocolEntryModal() {
            this.$bvModal.show(this.modals.addProtocolEntry);
        },
        selectProtocolEntry(id) {
            this.selectedProtocolEntryId = id;
        }
    }
};
</script>
