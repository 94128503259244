<template>
    <div class="events-date-settings">
        <FormGroupBuilder v-if="event" :fields="ioFields" :data="event" />
    </div>
</template>

<script>
import Size from "@/mixins/Size/Size";
import FormGroupBuilder from "@/components/FormGroupBuilder/FormGroupBuilder";
import CheckboxSelect from "@/components/CheckboxSelect/CheckboxSelect";

import { mapActions, mapGetters } from "vuex";

export default {
    name: "EventDateSettings",
    props: ["id"],
    mixins: [Size],
    components: { FormGroupBuilder, CheckboxSelect },
    data() {
        return {
            ioFields: {
                startdate: {
                    key: "startdate",
                    label: "Datum (Beginn)",
                    type: "date"
                },
                enddate: {
                    key: "enddate",
                    label: "Enddatum",
                    type: "date",
                    disabled: true
                },
                starttime: {
                    key: "starttime",
                    label: "Uhrzeit (Beginn)",
                    type: "time"
                },
                endtime: {
                    key: "endtime",
                    label: "Uhrzeit (Ende)",
                    type: "time",
                    disabled: true
                },
                date_hr: {
                    key: "date_hr",
                    type: "hr"
                },
                registration_deadline: {
                    key: "registration_deadline",
                    label: "Anmeldeschluss",
                    type: "date"
                }
            }
        };
    },
    watch: {
        "event.startdate": {
            handler(val) {
                this.ioFields.enddate.disabled = val ? false : true;
                this.ioFields.endtime.disabled = val ? false : true;
            },
            immediate: true
        }
    },
    computed: {
        ...mapGetters({
            busy: "events/getBusy"
        }),
        event() {
            const event = this.$store.getters["events/getEvent"](this.id);
            return event;
        }
    },
    created() {},
    methods: {}
};
</script>
