<template>
    <div class="locations-edit-view">
        <div v-if="modificationRecord">
            <b-tabs pills card vertical>
                <b-tab title="Stammdaten" active>
                    <FormGroupBuilder
                        :fields="ioFields"
                        :data="modificationRecord"
                    />
                </b-tab>
                <b-tab title="Kontaktdaten">
                    <PersonContactView
                        :data="modificationRecord"
                        :readonly="readonly"
                    />
                </b-tab>
                <b-tab title="Zugewiesene Kontakte" :disabled="true"> </b-tab>
                <b-tab v-if="showCambridgeIoFields" title="Cambridge Subcentre">
                    <FormGroupBuilder
                        :fields="cambridgeIoFields"
                        :data="modificationRecord"
                    />
                </b-tab>
                <b-tab
                    v-if="showCambridgePrepcentreIoFields"
                    title="Cambridge Prepcentre"
                >
                    <FormGroupBuilder
                        :fields="cambridgePrepcentreIoFields"
                        :data="modificationRecord"
                    />
                </b-tab>
                <template #tabs-end>
                    <div v-if="saveable">
                        <b-button
                            variant="primary"
                            class="d-block w-100 my-auto"
                            :size="size"
                            @click="localHandleModification"
                            >Speichern</b-button
                        >
                    </div>
                </template>
            </b-tabs>
        </div>
    </div>
</template>

<script>
import ModelView from "@/mixins/ModelView/ModelView";
import Size from "@/mixins/Size/Size";
import PersonContactView from "@/components/PersonContactView/PersonContactView";
import FormGroupBuilder from "@/components/FormGroupBuilder/FormGroupBuilder";
import { mapActions } from "vuex";

export default {
    name: "LocationsEditView",
    props: {
        id: Number,
        readonly: {
            type: Boolean,
            default: false
        },
        saveable: {
            type: Boolean,
            default: false
        }
    },
    components: {
        FormGroupBuilder,
        PersonContactView
    },
    mixins: [ModelView, Size],
    data() {
        return {
            ioFields: {
                id: {
                    key: "id",
                    label: "ID",
                    disabled: true
                },
                token: {
                    key: "token",
                    label: "Kundennummer"
                },
                name: {
                    key: "name",
                    label: "Name",
                    description: "Name der Einrichtung/Institution/Firma"
                },
                address: {
                    key: "address",
                    label: "Anschrift"
                },
                zip: {
                    key: "zip",
                    label: "PLZ"
                },
                location: {
                    key: "location",
                    label: "Ort"
                },
                type: {
                    key: "type",
                    label: "Typ",
                    type: "checkboxselect",
                    options: [
                        {
                            value: "cambridge_subcentre",
                            text: "Cambridge Subcentre"
                        },
                        {
                            value: "cambridge_prepcentre",
                            text: "Cambridge Prepcentre"
                        }
                    ]
                },
                description: {
                    key: "description",
                    label: "Info",
                    type: "textarea"
                }
            },
            cambridgeIoFields: {
                cambridge_centrenr: {
                    key: "cambridge_centrenr",
                    label: "Center Nr."
                },
                cambridge_subcentre: {
                    key: "cambridge_subcentre",
                    label: "Subcentre"
                }
            },
            cambridgePrepcentreIoFields: {
                cambridge_prep_id: {
                    key: "cambridge_prep_id",
                    label: "Preparation centre ID"
                },
                cambridge_prep_name: {
                    key: "cambridge_prep_name",
                    label: "Name"
                },
                cambridge_prep_short_name: {
                    key: "cambridge_prep_short_name",
                    label: "Short name"
                },
                cambridge_prep_type_of_funding: {
                    key: "cambridge_prep_type_of_funding",
                    label: "Type of funding"
                },
                cambridge_prep_contact: {
                    key: "cambridge_prep_contact",
                    label: "Contact name"
                },
                cambridge_prep_contact_mail: {
                    key: "cambridge_prep_contact_mail",
                    label: "Contact mail"
                },
                cambridge_prep_contact_number: {
                    key: "cambridge_prep_contact_number",
                    label: "Contact number"
                },
                cambridge_prep_country_code: {
                    key: "cambridge_prep_country_code",
                    label: "Country code"
                },
                cambridge_prep_area_code: {
                    key: "cambridge_prep_area_code",
                    label: "Area code"
                },
                cambridge_prep_access_status: {
                    key: "cambridge_prep_access_status",
                    label: "Access status"
                },
                cambridge_rel_sub_prep: {
                    key: "cambridge_rel_sub_prep",
                    label: "Zugewiesenes Subcenter",
                    type: "checkboxselect"
                }
            },
            showCambridgeIoFields: false,
            showCambridgePrepcentreIoFields: false,
            actions: {
                create: "locations/createDataExternal"
            }
        };
    },
    computed: {
        dataset() {
            return this.$store.getters["locations/getDataById"](this.id);
        }
    },
    created() {
        this.fetchSubcentre();
    },
    watch: {
        dataset: {
            handler(val) {
                if (val.length > 0) {
                    this.modificationRecord = { ...val[0] };
                } else {
                    this.modificationRecord = {};
                }
            },
            immediate: true
        },
        "modificationRecord.type": {
            handler(val) {
                if (val) {
                    if (val.includes("cambridge_subcentre")) {
                        this.showCambridgeIoFields = true;
                    } else {
                        this.showCambridgeIoFields = false;
                    }

                    if (val.includes("cambridge_prepcentre")) {
                        this.showCambridgePrepcentreIoFields = true;
                    } else {
                        this.showCambridgePrepcentreIoFields = false;
                    }
                }
            },
            immediate: true
        },
        readonly: {
            handler(val) {
                Object.entries(this.ioFields).forEach(([key, value]) => {
                    if (!this.ioFields[key].disabled) {
                        this.ioFields[key].disabled = val;
                    }
                });
                Object.entries(this.cambridgeIoFields).forEach(
                    ([key, value]) => {
                        if (!this.cambridgeIoFields[key].disabled) {
                            this.cambridgeIoFields[key].disabled = val;
                        }
                    }
                );
                Object.entries(this.cambridgePrepcentreIoFields).forEach(
                    ([key, value]) => {
                        if (!this.cambridgePrepcentreIoFields[key].disabled) {
                            this.cambridgePrepcentreIoFields[
                                key
                            ].disabled = val;
                        }
                    }
                );
            },
            immediate: true
        },
        saveable: {
            handler(val) {
                this.modificationMode = val ? "create" : null;
            },
            immediate: true
        }
    },
    methods: {
        async fetchSubcentre() {
            await this.$store.dispatch("examsCambridgeSubcentre/fetchData");
            const selectData = this.$store.getters[
                "examsCambridgeSubcentre/getSelectData"
            ];
            this.cambridgePrepcentreIoFields.cambridge_rel_sub_prep.options = selectData;
        },
        async localHandleModification(event) {
            const answer = await this.handleModification(event);
            console.log(answer);
            this.$emit("created", answer);
        }
    }
};
</script>
