<template>
    <div class="event-protocol-edit">
        <FormGroupBuilder :fields="ioFields" :data="data"></FormGroupBuilder>
        <b-form-group
            key="additional_files"
            label-cols-sm="3"
            :label-cols-lg="4"
            label-for="additional_files"
            :label-size="size"
            label="Angehängte Dateien"
        >
            FILES
        </b-form-group>
        <b-form-group
            key="additional_files"
            label-cols-sm="3"
            :label-cols-lg="4"
            label-for="additional_files"
            :label-size="size"
            label="Datei(en) anhängen"
        >
            <b-file :size="size" />
        </b-form-group>
    </div>
</template>

<script>
import Size from "@/mixins/Size/Size";

import FormGroupBuilder from "@/components/FormGroupBuilder/FormGroupBuilder";

export default {
    name: "EventProtocolEdit",
    mixins: [Size],
    components: { FormGroupBuilder },
    data() {
        return {
            ioFields: {
                id: {
                    key: "id",
                    label: "ID",
                    disabled: true
                },
                title: {
                    key: "title",
                    label: "Titel"
                },
                description: {
                    key: "description",
                    label: "Beschreibung",
                    type: "textarea"
                },
                checked_date: {
                    key: "checked_date",
                    label: "Erledigt am",
                    type: "date",
                    disabled: true
                },
                checked_flag: {
                    key: "checked_flag",
                    label: "Erledigt",
                    type: "checkbox"
                },
                future_hr: {
                    key: "future_hr",
                    type: "hr"
                },
                in_future: {
                    key: "in_future",
                    label: "Erledigung in Zukunft",
                    type: "checkbox"
                },
                in_future_date: {
                    key: "in_future_date",
                    label: "Erledigt zu",
                    type: "date",
                    disabled: true
                },
                file_hr: {
                    key: "file_hr",
                    type: "hr"
                }
            },
            data: {}
        };
    },
    watch: {
        "data.in_future": {
            handler(val) {
                this.ioFields.in_future_date.disabled = !val;
            }
        }
    },
    methods: {}
};
</script>
